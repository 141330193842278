import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { HttpClient } from '@angular/common/http';
import { map, Observable, shareReplay } from 'rxjs';
import { Environment } from '../../types/environment';
import { Trailer } from '../../types/trailer';

@Injectable({
  providedIn: 'root',
})
export class TrailerTypeService {
  readonly API = `${this.env.apiUrl}/trailerTypeClassifier`;
  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  getAll() {
    return this.http.get<Trailer[]>(`${this.API}/getAll`).pipe(shareReplay());
  }

  getTrailerTypesMap(): Observable<Map<string, Trailer>> {
    return this.getAll().pipe(
      map(trailers => {
        return new Map(trailers.map(trailer => [trailer.code, trailer]));
      }),
    );
  }
}
