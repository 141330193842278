import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of, shareReplay, tap } from 'rxjs';
import { Environment } from '../../types/environment';
import {
  TransitPoint,
  TransitPointType,
  TransitPointTypeItem,
} from '../../types/transit-point';

@Injectable({
  providedIn: 'root',
})
export class TransitPointService {
  readonly API = `${this.env.apiUrl}/transitPointClassifier`;
  private readonly types = [
    {
      name: 'Фито',
      value: TransitPointType.FITO,
    },
    {
      name: 'EX',
      value: TransitPointType.EX,
    },
    {
      name: 'Оператор',
      value: TransitPointType.OPERATOR,
    },
    {
      name: 'Граница РБ',
      value: TransitPointType.RB_BORDER,
    },
    {
      name: 'Граница РФ',
      value: TransitPointType.RF_BORDER,
    },
    {
      name: 'Весы',
      value: TransitPointType.SCALES,
    },
  ];

  transitPoints$ = new BehaviorSubject<TransitPoint[]>([]);
  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  save(transitPoint: TransitPoint) {
    return this.http.post<TransitPoint>(`${this.API}/save`, transitPoint);
  }

  deleteById(id: number) {
    return this.http.post<TransitPoint>(
      `${this.API}/deleteById?id=${id}`,
      null,
    );
  }

  getTypesMap(): Map<TransitPointType, TransitPointTypeItem> {
    return new Map(this.types.map(point => [point.value, point]));
  }

  getTypes(): Observable<TransitPointTypeItem[]> {
    return of(this.types).pipe(shareReplay());
  }

  getTransitPointsMap() {
    return this.transitPoints$.pipe(
      map(points => new Map(points.map(point => [point.id, point]))),
      shareReplay(),
    );
  }

  preload() {
    return this.http
      .get<TransitPoint[]>(`${this.API}/getAll`)
      .pipe(tap(points => this.transitPoints$.next(points)));
  }

  getTransitPoints() {
    return this.transitPoints$.pipe(
      map(points => {
        const typesMap = this.getTypesMap();
        return points.map(point => {
          return {
            ...point,
            itemText: `${typesMap.get(point.type as TransitPointType)?.name} - ${point.name} - ${point.address}`,
            itemSearch: `${point.name}-${point.address}`,
          };
        });
      }),
      shareReplay(),
    );
  }
}
