export enum CurrencyLetter {
  EUR = 'EUR',
  USD = 'USD',
}

export const CURRENCIES = [
  {
    name: 'Евро',
    letterCode: CurrencyLetter.EUR,
  },
  {
    name: 'Доллар США',
    letterCode: CurrencyLetter.USD,
  },
];
