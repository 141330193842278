import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceNumber',
  standalone: true,
  pure: true,
})
export class SpaceNumberPipe implements PipeTransform {
  transform(value: Nullable<string>): unknown {
    return value?.replaceAll(',', ' ');
  }
}
