export type TransitPoint = {
  id: Nullable<number>;
  type: TransitPointType | null;
  name: string;
  address: string;
  latitude: string;
  longitude: string;
};

export enum TransitPointType {
  FITO = 'FITO',
  EX = 'EX',
  RB_BORDER = 'RB_BORDER',
  OPERATOR = 'OPERATOR',
  RF_BORDER = 'RF_BORDER',
  SCALES = 'SCALES',
}

export type TransitPointTypeItem = { name: string; value: TransitPointType };
